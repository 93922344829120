import {
	BlogHero,
	BlogPosts,
	ContentWithLink,
	Spacer,
	Cta,
	Pagination,
} from 'components';
import about from 'images/about.jpg';
import { graphql, navigate, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

export type BlogProps = {
	pageContext: {
		next: string;
		previous: string;
		category: string;
		frontmatter: {
			postsPagesCount: number;
		};
	};
	allMdx: {
		edges: {
			node: {
				frontmatter: {
					title: string;
					author: string;
					slug: string;
					image: {
						childImageSharp: {
							gatsbyImageData: ImageDataLike;
						};
					};
					image_title: string;
					summary: string;
				};
			};
		}[];
	};
	mdx: {
		body: string;
		frontmatter: {
			date: string;
			author: string;
			image: {
				childImageSharp: {
					gatsbyImageData: ImageDataLike;
				};
			};
			image_title: string;
			summary: string;
		};
	};
};

export default function BlogTemplate(props: PageProps<BlogProps>) {
	return (
		<>
			<BlogHero
				title="Blog."
				subtitle="Read recent articles from our web development blog"
				description="We are a software house that turns an **awesome idea** into a product"
			/>
			<Spacer height={80} responsive={{ md: 60, sm: 40 }} />
			<BlogPosts props={props} />
			<Pagination
				total={props.pageContext.frontmatter.postsPagesCount}
				onChange={(page) => {
					if (page === 1) {
						navigate(`/blog`);
					} else {
						navigate(`/blog/page/${page}`);
					}
				}}
				current={props.pageContext.frontmatter.currentPage}
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			<Cta
				title="Take partnership to the next level"
				subtitle="Some clients stay with us for over 4 years. With our experienced team,  we’re ready to assist you with full-cycle development"
				buttonText="Get in touch"
				to="/contact"
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			<ContentWithLink
				headline="No **Department** work as **we do**"
				content="Behind the Deparment of Web Software House stands our dream team of experienced frontend/backend developers, product designer and marketing specialist. We design, develop and maintain software built on top on WordPress and Laravel."
				image={{
					src: about,
					title: 'about',
				}}
				button={{
					children: 'Get in touch',
					to: '/contact',
				}}
				imagePosition="right"
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
		</>
	);
}

export const pageQuery = graphql`
	query BLOG_POSTS_PAGE($skip: Int!, $limit: Int!) {
		allMdx(
			filter: { frontmatter: { slug: { regex: "/^/blog//i" } } }
			sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					id
					body
					frontmatter {
						slug
						title
						author
						date
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
						image_title
						summary
					}
				}
			}
		}
	}
`;
